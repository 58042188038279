import { render, staticRenderFns } from "./FeedWrapper.vue?vue&type=template&id=65283ede&scoped=true&"
import script from "./FeedWrapper.vue?vue&type=script&lang=js&"
export * from "./FeedWrapper.vue?vue&type=script&lang=js&"
import style0 from "./FeedWrapper.vue?vue&type=style&index=0&id=65283ede&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65283ede",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\divak\\Downloads\\well-known (1)\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65283ede')) {
      api.createRecord('65283ede', component.options)
    } else {
      api.reload('65283ede', component.options)
    }
    module.hot.accept("./FeedWrapper.vue?vue&type=template&id=65283ede&scoped=true&", function () {
      api.rerender('65283ede', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/core/components/FeedWrapper.vue"
export default component.exports